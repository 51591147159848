import "./styles.css";
import { chakra } from "@chakra-ui/system";
import { Tooltip } from "@chakra-ui/react";

const reactSimpleMaps = require("react-simple-maps");
const { ComposableMap, Geographies, Geography } = reactSimpleMaps;

const ChakraGeography = chakra(Geography);

const visitedCountries = new Set([
  "UZB",
  "MYS",
  "SGP",
  "KAZ",
  "USA",
  "CAN",
  "MAR",
  "ESP",
  "MEX",
  "KOR",
  "VNM",
  "GTM",
  "RUS",
  "PRK",
  "CHN",
  "BRA",
  "AUS",
  "GBR",
  "BHS",
  "JPN",
  "EGY",
  "NZL",
  "THA",
  "MMR",
  "FRA",
  "DEU",
  "ZAF",
  "NGA",
  "JAM",
  "ECU",
]);

const VisitedCountriesMap = () => {
  return (
    <ComposableMap>
      <Geographies geography="/ne_110m_coastline.geojson">
        {({ geographies }: { geographies: any }) =>
          geographies.map((geo: any) => {
            return (
              <ChakraGeography
                key={geo.rsmKey}
                geography={geo}
                stroke-width="3"
                fill-opacity="0%"
                stroke="black"
              />
            );
          })
        }
      </Geographies>
      <Geographies geography="/country_geographies.json">
        {({ geographies }: { geographies: any }) =>
          geographies.map((geo: any) => {
            const haveVisited = visitedCountries.has(geo.id);
            return (
              <Tooltip label={geo.properties.name} placement="right-end">
                <ChakraGeography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={haveVisited ? "#8743e2" : "white"}
                  stroke-width="1"
                  stroke="white"
                  _hover={{ fill: haveVisited ? "#0693E3" : "pink" }}
                />
              </Tooltip>
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
};

export default VisitedCountriesMap;
