import "./styles.css";
import ReactCountryFlag from "react-country-flag";

const visitedCountries = [
  ["Uzbekistan", "UZ"],
  ["Malaysia", "MY"],
  ["Singapore", "SG"],
  ["Kazakhstan", "KZ"],
  ["United States", "US"],
  ["Canada", "CA"],
  ["Morocco", "MA"],
  ["Spain", "ES"],
  ["Mexico", "MX"],
  ["South Korea", "KR"],
  ["Vietnam", "VN"],
  ["Guatemala", "GT"],
  ["Russia", "RU"],
  ["North Korea", "KP"],
  ["China", "CN"],
  ["Brazil", "BR"],
  ["Australia", "AU"],
  ["United Kingdom", "GB"],
  ["Bahamas", "BS"],
  ["Japan", "JP"],
  ["Egypt", "EG"],
  ["New Zealand", "NZ"],
  ["Thailand", "TH"],
  ["Myanmar", "MM"],
  ["France", "FR"],
  ["Germany", "DE"],
  ["South Africa", "ZA"],
  ["Nigeria", "NG"],
  ["Jamaica", "JM"],
  ["Ecuador", "EC"],
];

const VisitedCountriesFlags = () => {
  const visitedFlags = visitedCountries.map((tuple) => (
    <li>
      {tuple[0]}
      <ReactCountryFlag countryCode={tuple[1]} />
    </li>
  ));
  return (
    <ul
      style={{
        maxHeight: 200,
        overflow: "auto",
        border: "1px solid",
        alignItems: "center",
        maxWidth: 200,
      }}
    >
      {visitedFlags}
    </ul>
  );
};

export default VisitedCountriesFlags;
