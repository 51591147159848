import React from "react";
import VisitedCountriesMap from "../visited-countries-map/VisitedCountriesMap";
import VisitedCountriesFlags from "../visited-countries-map/VisitedCountriesFlags";
import "./Home.css";
import { Button, VStack, HStack, WrapItem, Link } from "@chakra-ui/react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

function Home() {
  return (
    <div className="Home">
      <header className="Home-header">
        <p style={{ padding: "10px" }}>Welcome to Miraziz's website!</p>
      </header>
      <div>
        <p />
        <VStack>
          <WrapItem>
            <Link
              href="https://linkedin.com/in/miraziz"
              style={{ textDecoration: "none", paddingTop: "10px" }}
              isExternal
            >
              <Button colorScheme="linkedin" leftIcon={<FaLinkedin />}>
                LinkedIn
              </Button>
            </Link>
          </WrapItem>
          <WrapItem>
            <Link
              href="https://www.instagram.com/miraziztravel"
              style={{ textDecoration: "none" }}
              isExternal
            >
              <Button colorScheme="yellow" leftIcon={<FaInstagram />}>
                Instagram
              </Button>
            </Link>
          </WrapItem>
        </VStack>
      </div>
      <div style={{ alignItems: "center" }}>
        <VisitedCountriesMap />
        <VisitedCountriesFlags />
      </div>
      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <VisitedCountriesMap />
        <span style={{ padding: 10 }}>
          <VisitedCountriesFlags />
        </span>
      </div> */}
    </div>
  );
}

export default Home;
